<template>
<div  class="justify-center py-2">

    <div class="d-flex justify-center">
        <v-btn @click.prevent="loadItems(1)" :disabled="items.current_page === 1">
            <v-icon dark>first_page</v-icon>
        </v-btn>
        <v-btn @click.prevent="loadItems(items.current_page -1)" :disabled="items.current_page === 1">
            <v-icon dark>chevron_left</v-icon>
        </v-btn>
        <v-btn @click.prevent="loadItems(items.current_page +1)" :disabled="items.current_page === items.last_page">
            <v-icon dark>chevron_right</v-icon>
        </v-btn>
        <v-btn @click.prevent="loadItems(items.last_page)" :disabled="items.current_page === items.last_page">
            <v-icon dark>last_page</v-icon>
        </v-btn>
    </div>
    <div class="d-flex justify-center py-2">
        <p class="caption blue-grey--text text--darken-1"> Total de Registro: {{items.total}}</p>
    </div>
</div>
</template>

<script>
export default {
    props: {
        items: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    current_page: 0,
                    data: {},
                    last_page: 0,
                    per_page: 0,
                    total: 0
                }
            }
        }
    },
    methods: {
        loadItems(page) {
            this.$emit('loadItems', page)
        }
    }
}
</script>

<style scoped>

</style>
