<template>
<span>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="670px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="stor_gf_imagem" :src="`${base_url}upload/store_gift_list/${stor_gf_imagem}`" class="foto-ampliada">
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Listas de Presentes</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="loadStoreGiftLists">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'store-gift-list.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Lista
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field xs6 v-model="search" append-icon="search" label="Localizar na Tabela" outlined></v-text-field>
            <v-spacer></v-spacer>
            <!--<search-form @pesquisarDados="filter"></search-form>-->
            <v-flex xs6>
                <v-text-field v-model="busca" append-icon="search" label="Localizar no Banco de Dados" outlined @keyup.13.prevent="loadStoreGiftLists"></v-text-field>
            </v-flex>
        </v-card-title>
        <v-data-table :headers="headers" :items="storegiftlist.data" :search="search" :rowsPerPageItems="rowsPerPageItems" hide-default-footer>

            <template v-slot:[`item.stor_gf_imagem`]="{ item }">
                <img v-if="item.stor_gf_imagem" :src="`${base_url}upload/store_gift_list/${item.stor_gf_imagem}?${uuid}`" class="avatar" @click="ampliarFoto(item.stor_gf_imagem)">
                <img v-if="!item.stor_gf_imagem" :src="`${base_url}upload/imagem.png`" class="avatar">
            </template>

            <template v-slot:[`item.usu_nome`]="{ item }">
                <td>
                    {{ item.usuario.usu_nome }}
                </td>
            </template>

            <template v-slot:[`item.stor_gf_finalizada`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_gf_finalizada" @change="onChangeEventHandlerFinalizada(item.stor_gf_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.stor_gf_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_gf_status" @change="onChangeEventHandlerStatus(item.stor_gf_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editStoreGiftList(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailStoreGiftList(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="listStoreGiftListItems(item)" v-on="on">
                            <v-icon dark class="mdi mdi-view-list"></v-icon>
                        </v-btn>
                    </template>
                    <span>Itens da Lista</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>

        <pagination @loadItems="loadStoreGiftLists" :items="storegiftlist"></pagination>

    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";
import SearchForm from "../partials/SearchComponent";
import Pagination from '../partials/PaginationComponent';
import {
    uuid
} from "vue-uuid";

export default {
    name: "StoreGiftListsComponent",
    created() {
        this.loadStoreGiftLists(1);
    },
    computed: {
        params() {
            return {
                search: '',
                page: this.storegiftlist.current_page
            };
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [
                {
                    text: 'Imagem',
                    align: 'left',
                    sortable: false,
                    value: 'stor_gf_imagem'
                },
                {
                    sortable: false,
                    text: "Usuário",
                    value: "usu_nome"
                },
                {
                    sortable: false,
                    text: "Nome do Noivo",
                    value: "stor_gf_nvo_nome"
                },
                {
                    sortable: false,
                    text: "Nome da Noiva",
                    value: "stor_gf_nva_nome"
                },
                {
                    sortable: false,
                    text: "Data do Casamento",
                    value: "stor_gf_data_casamento"
                },
                {
                    sortable: false,
                    text: "Finalizada",
                    value: "stor_gf_finalizada"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "stor_gf_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            storegiftlist: [],
            busca: '',
            dialog_foto: false,
            stor_gf_imagem: null,
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        ampliarFoto(foto) {
            this.stor_gf_imagem = foto;
            this.dialog_foto = true;
        },
        loadStoreGiftLists(page) {
            this.$store
                .dispatch("loadStoreGiftLists", {
                    search: this.busca,
                    page: page
                })
                .then(response => {
                    this.storegiftlist = response;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Erro",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        editStoreGiftList(item) {
            this.$router.push({
                name: "store-gift-list.edit",
                params: {
                    stor_gf_id: item.stor_gf_id
                }
            });
        },
        detailStoreGiftList(item) {
            this.$router.push({
                name: "store-gift-list.detail",
                params: {
                    stor_gf_id: item.stor_gf_id
                }
            });
        },
        listStoreGiftListItems(item) {
            this.$router.push({
                name: "store-gift-list-item.add",
                params: {
                    stor_gf_id: item.stor_gf_id
                }
            });
        },
        deleteStoreGiftList(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyStoreGiftList", item.stor_gf_id)
                        .then(response => {
                            if (response.data.success) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.loadStoreGiftLists();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "warning",
                                    title: "Atenção!",
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: error.data.message,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerStatus(stor_gf_id, value) {
            this.$store
                .dispatch("updateStoreGiftListStatus", {
                    stor_gf_id: stor_gf_id,
                    stor_gf_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Produto atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Produto",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerFinalizada(stor_gf_id, value) {
            this.$store
                .dispatch("updateStoreGiftListFinalizada", {
                    stor_gf_id: stor_gf_id,
                    stor_gf_finalizada: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        filter(filter) {
            this.params.search = filter;
            this.loadStoreGiftLists(1);
        }
    },
    components: {
        SearchForm: SearchForm,
        Pagination: Pagination,
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
